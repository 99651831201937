<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="edit = true"
        >編集</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <template>
        <v-autocomplete
          v-model="selectIncode"
          :items="incodes"
          label="所属"
          :rules="[Rules.Required]"
          outlined
          :disabled="!add"
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-text-field
          v-model.number="basicUnitPrice"
          label="所属標準単価"
          suffix="円"
          type="number"
          prepend-icon="mdi-wallet"
          outlined
        ></v-text-field>
        <!--v-text-field
          v-model.number="mealUnitPrice"
          label="所属食事単価"
          suffix="円"
          type="number"
          prepend-icon="mdi-wallet"
          outlined
        ></v-text-field-->
        <!--v-text-field
          v-model.number="parkingUnitPrice"
          label="所属駐車場単価"
          suffix="円"
          type="number"
          prepend-icon="mdi-wallet"
          outlined
        ></v-text-field-->
        <v-autocomplete
          v-model="selectPermitFixTime"
          :items="permitFixTimeItems"
          label="時間変更可否"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <!-- <div class="d-flex">
          <v-text-field
            v-model.number="activeHour"
            label="標準日中活動時間帯勤務時間＜時間＞"
            suffix="："
            type="number"
            prepend-icon="mdi-clock-digital"
            outlined
          ></v-text-field>
          <v-text-field
            v-model.number="activeMinute"
            label="標準日中活動時間帯勤務時間＜分＞"
            type="number"
            outlined
          ></v-text-field>
        </div> -->
      </template>
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import ShozokuExp from "@/models/ShozokuExp";
import { CODE_GROUP as CodeGroup, NOT_SET_CODE as NotSetCode } from "@/defines";

export default {
  name: "ShozokuExpDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      incodes: [],
      selectIncode: { code: "", name: "" },
      selectPermitFixTime: { code: "", name: "" },
      sort: null,
      basicUnitPrice: 0,
      mealUnitPrice: 0,
      parkingUnitPrice: 0,
      permitFixTimeItems: [],
      activeHour: 0,
      activeMinute: 0
    };
  },
  computed: {
    Title() {
      return (
        this.title + " " + (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    }
  },
  methods: {
    async postRecord(param) {
      await this.$post(this.Paths.shozokuExp, param);
      this.add = false;
    },
    complete() {
      console.log("complete", this.kbn);
      if (!this.$refs.form.validate()) return;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit");
      this.$loading();
      try {
        if (this.activeHour == null) this.activeHour = 0;
        if (this.activeMinute == null) this.activeMinute = 0;
        const baseDaytimeActiveHour = this.activeHour + ":" + this.activeMinute;

        const param = new ShozokuExp(
          this.selectIncode.code,
          this.selectPermitFixTime.code,
          this.basicUnitPrice,
          this.mealUnitPrice,
          this.parkingUnitPrice,
          baseDaytimeActiveHour
        );

        console.log("submit_param", param);
        await this.postRecord(param);
        this.$info("更新しました。", "所属マスタ拡張");
        this.edit = false;
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    back() {
      const path = "/list/shozoku-exp";
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    async getShozoku() {
      console.log("getShozoku");
      return await this.$get(this.Paths.shozoku);
    },
    async getChange() {
      console.log("getChange");
      const param = { kbn: CodeGroup.CHANGE };
      const encode = encodeURI(JSON.stringify(param));
      console.log(encode, this.Paths.codeMasterPulldown);
      return await this.$get(this.Paths.codeMasterPulldown, "query=" + encode);
    }
  },
  async created() {
    console.log(this.name, "created");
    this.params = this.$route.params;

    this.$loading();
    try {
      if (this.prosess && typeof this.prosess === "string")
        this.prosess = this.prosess.toLowerCase();

      switch (this.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      this.incodes = await this.getShozoku();
      this.permitFixTimeItems = await this.getChange();
      this.permitFixTimeItems = this.permitFixTimeItems.filter(
        e => e.code != NotSetCode.CHANGE
      );

      if (!this.add) {
        this.incodes = this.incodes.filter(
          e => e.code === String(this.args.incode)
        );
        if (this.incodes.length > 0) {
          this.selectIncode.code = this.incodes[0].code;
          this.selectIncode.name = this.incodes[0].name;
        }

        const permitFixTime =
          this.args.permitFixTime == null ? "0" : this.args.permitFixTime;

        const filterPermitFixTime = this.permitFixTimeItems.filter(
          e => e.code === String(permitFixTime)
        );
        if (filterPermitFixTime.length > 0) {
          this.selectPermitFixTime.code = filterPermitFixTime[0].code;
          this.selectPermitFixTime.namae = filterPermitFixTime[0].name;
        }

        this.basicUnitPrice =
          this.args.basicUnitPrice == null ? 0 : this.args.basicUnitPrice;

        this.mealUnitPrice =
          this.args.mealUnitPrice == null ? 0 : this.args.mealUnitPrice;

        this.parkingUnitPrice =
          this.args.parkingUnitPrice == null ? 0 : this.args.parkingUnitPrice;

        if (
          this.args.baseDaytimeActiveHour !== null &&
          this.args.baseDaytimeActiveHour.indexOf(":") > 0
        ) {
          const hm = this.args.baseDaytimeActiveHour.split(":");
          this.activeHour = Number(hm[0]);
          this.activeMinute = Number(hm[1]);
        }
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style></style>
